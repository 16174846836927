import {combineLatest, merge, Observable, Subject} from 'rxjs';
import {filter, map, startWith, take, takeWhile, tap} from 'rxjs/operators';
import find from 'lodash-es/find';
import * as dayjs from 'dayjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {Group} from '../models/group';
import {FormEvent, ViewEvent} from '../event/event';
import {AddEventAction, GetEventAction, UpdateEventAction} from '../actions/klassenbuch';
import {UiSetBackUrl, UiSetTitleAction} from '../actions/ui';
import {Label} from '../admin/labels/labels.models';
import * as reducers from '../reducers';
import {FileAttachment} from "../attachment/attachment.model";
import Select from '../sdx/form/Select';
import {IS_APP} from '../app.constants';
import {AnalyticsService} from '../services/analytics';
import {AddToastAction} from '../alerts/alerts.actions';
import {HelloclassRRuleService} from "../services/rrule.service";
import {ConfirmationModalComponent} from "../confirmation-modal/confirmation-modal";
import {ActionsheetService} from "../services/actionsheet.service";

const MAX_SLOTS = 9;
const SLOTS_LABELS = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
const STATES = [
  {
    id: 'published',
    text: 'Veröffentlichen'
  },
  {
    id: 'draft',
    text: 'Entwurf'
  }
]
const DATE_FORMAT = "YYYY-MM-DD"
const EMPTY_LABEL = {id: -1, title: '', color: ''}
const PRIVACY = [
  {
    id: 'public',
    text: 'Öffentlich',
  },
  {
    id: 'private',
    text: 'Privat',
  }
]
const RECURRENCES = [
  {
    id: -1,
    text: 'Einmalig'
  },
  {
    id: 3,
    text: 'Täglich'
  },
  {
    id: 2,
    text: 'Wöchentlich'
  },
  {
    id: 1,
    text: 'Monatlich'
  },
]

@Component({
  selector: 'hc-event-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="form" (submit)="formSubmit()" class="event-form">
      <div class="container main__container main__container--event-form">
        <div class="form--action">
          <div class="form-actions event-actions">
            <div class="container">
              <button
                class="btn btn--innershadow btn--blue btn--bigger form-actions__item form-actions__ok"
                [disabled]="!form.valid || (disableSubmit$ | async)"
                type="submit"
                data-cy="event-form-save-button">{{ 'Speichern' | translate }}
              </button>
              <div class="btn-group state-dropdown dropdown" dropdown (isOpenChange)="onOpenChange()"
                   [autoClose]="true">
                <button type="button"
                        class="btn btn-primary unstyled-button state-dropdown__button btn--dark-text"
                        dropdownToggle>
                  {{ state | translate }}<span class="icon-arrow-down dropdown__icon"
                                               [ngClass]="{'dropdown__icon--inverted': invertedIcon}"></span>
                </button>
                <ul *dropdownMenu role="menu" class="dropdown-menu" aria-labelledby="single-button">
                  <li role="menuitem" *ngFor="let state of stateSelection" [value]="state.id">
                    <button (click)="selectState(state.id)"
                            class="dropdown-item unstyled-button btn--dark-text btn--dropdown"
                            type="button">{{ state.text | translate }}
                    </button>
                  </li>
                </ul>
              </div>
              <button
                class="qa-abort-button form-actions__item form-actions__cancel unstyled-button icon-cross pull-right btn--dark-text"
                type="button" aria-label="cancel" (click)="cancel()"></button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedLabel$ | async"
        class="event-form-main" [ngClass]="'bg-' + (selectedLabel$ | async).color">
        <div class="container">
          <div class="form-group">
            <input (focus)="labelSelectorOpen = true" (focusout)="labelSelectorOpen = false"
                   formControlName="title" name="title" type="text" class="form-control" aria-label="Title"
                   placeholder="{{'Titel hinzufügen' | translate}}">
            <textarea #description_field
                      class="form-control event__description_input"
                      type="text" name="description"
                      formControlName="description"
                      placeholder="{{'Beschreibung' | translate}}"
                      data-cy="event-form-description"></textarea>
          </div>
          <div class="form-group">
            <hc-label-selection [labels]="labels$ | async"
                                [selectedLabel]="selectedLabel$ | async"
                                (labelSelected)="labelSelected($event)"></hc-label-selection>
            <input type="hidden" formControlName="label">
          </div>
        </div>
      </div>
      <div class="container">
        <fieldset class="eventform-section">
          <label class="heading-2" translate>Zeiteinstellungen</label>
          <div class="time-selection">
            <div class="form-group time-selection__item--30">
              <label class="hc-label" for="date">{{ 'Datum' | translate }}</label>
              <div *ngIf="dateError">
                <p class="form__input_error text-size-small">{{ dateErrorText }}</p>
              </div>
              <p>
                <button aria-label="Show date selection"
                        type="button"
                        class="date-selection selection-toggle selection-toggle--form unstyled-button"
                        (click)="dateSelectorOpen = !dateSelectorOpen"
                        [ngClass]="{'is-active': dateSelectorOpen}"
                        [bsValue]="date"
                        bsDatepicker
                        (bsValueChange)="startDateChange($event)"
                        [daysDisabled]="[0, 6]"
                        [minDate]="minDate"
                        [bsConfig]="{ containerClass: 'theme-dark-blue' }"
                        data-cy="event-date-selection"
                >{{ date | dateFormatFilter:'dd, DD.MM.YYYY' }}<span class="icon-arrow-down"></span>
                </button>
              </p>
            </div>
            <div class="select-start select time-selection__item--25">
              <label class="hc-label" for="start">{{ 'Beginn' | translate }}</label>
              <select class="qa-start" formControlName="start" id="start" data-init="auto" tabindex="1"
                      #startSelection>
                <option *ngFor="let slot of slotLabels;let i = index" [value]="i">
                  <span *ngIf="slot == 1">{{ slot }} <span translate>Lektion</span></span>
                  <span *ngIf="slot != 1">{{ slot }} <span translate>Lektionen</span></span>
                </option>
              </select>
            </div>
            <div class="select-duration select time-selection__item--30 select--last">
              <label class="hc-label" for="duration">{{ 'Dauer' | translate }}</label>
              <select class="qa-duration" formControlName="duration" id="start" data-init="auto"
                      tabindex="2" #durationSelection>
                <option *ngFor="let slots of (durationSelection$ | async);" [value]="slots['duration']">
                  {{ slots['text'] | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="recurrence-selection">
            <div class="select-duration select time-selection__item--30">
              <label class="hc-label" for="recurrences">{{ 'Wiederholung' | translate }}</label>
              <select data-cy="recurrences" formControlName="recurrences" id="recurrences"
                      data-init="auto" tabindex="3" #recurrencesSelection>
                <option *ngFor="let recurrence of recurrencesSelectionOptions"
                        [value]="recurrence['id']">
                  {{ recurrence['text'] | translate }}
                </option>
              </select>
            </div>
            <div *ngIf="(recurrencesChanges$ | async) != -1"
                 class="form-group time-selection__item--30"
                 data-cy="recurrences-end-date">
              <label class="hc-label" for="end_data">{{ 'Ende Wiederholung' | translate }}</label>
              <button aria-label="Show end date selection"
                      type="button"
                      class="date-selection selection-toggle selection-toggle--form unstyled-button recurrence-button"
                      (click)="endDateSelectorOpen = !endDateSelectorOpen"
                      [ngClass]="{'is-active': endDateSelectorOpen}"
                      [bsValue]="endDate"
                      bsDatepicker
                      (bsValueChange)="endDateChange($event)"
                      [daysDisabled]="[0, 6]"
                      [minDate]="minEndDate"
                      [bsConfig]="{ containerClass: 'theme-dark-blue' }"
                      data-cy="recurrences-end-date-selection"
              >{{ endDate | dateFormatFilter:'dd, DD.MM.YYYY' }}<span class="icon-arrow-down"></span>
              </button>
            </div>
          </div>
        </fieldset>
        <fieldset class="eventform-section">
          <label class="heading-2" translate>Gruppe und Sichtbarkeit</label>
          <div class="select-group select qa-select-group">
            <label class="hc-label" for="group">{{ 'Gruppe' | translate }}</label>
            <select class="qa-group" formControlName="group" id="group" data-init="auto" tabindex="1"
                    #groupSelection>
              <option *ngFor="let group of groups$ | async" [value]="group.id">
                {{ group.display_name }}
              </option>
            </select>
          </div>
          <div class="select-privacy select">
            <label class="hc-label" for="privacy">{{ 'Sichtbarkeit der Kommentare' | translate }}</label>
            <select data-cy="privacy" formControlName="isPrivate" id="privacy"
                    data-init="auto" tabindex="3" #privacySelection>
              <option *ngFor="let privacy of privacySelectionOptions" [value]="privacy['id']">
                {{ privacy['text'] | translate }}
              </option>
            </select>
            <!--                        <div *ngIf="!(hasPremium$ | async)">-->
            <!--                            <h4 class="privacy-heading" for="privacy">{{'Sichtbarkeit der Kommentare' | translate}}</h4>-->
            <!--                            <p>{{'Was genau ist «Sichtbarkeit von Kommentaren»?' |translate}}</p>-->
            <!--                            <p><a-->
            <!--                                href="https://helloclass.zendesk.com/hc/de/articles/360013895059-Sichtbarkeit-von-Kommentaren-Visibilit%C3%A9-des-commentaires-Visibilit%C3%A0-del-commenti">{{'Erfahre mehr dazu' |translate}}</a>-->
            <!--                            </p>-->
            <!--                        </div>-->
          </div>
        </fieldset>
        <fieldset class="eventform-section">
          <label class="heading-2" for="length" translate>Dateien</label>
          <div class="form-group">
            <input type="hidden" name="uploads" formControlName="uploads">
            <hc-event-attachment
              [editable]="true"
              [uploads]="event.uploads"
              [labelColor]="event.labelColor"
              (addUpload)="addUpload($event)"
              (removeUpload)="removeUpload($event)"
              (errorOnUpload)="errorOnUpload()"
              class="attachment-bottom-padding row">
            </hc-event-attachment>
          </div>
        </fieldset>
      </div>
    </form>
    <hc-confirmation-modal
      [title]="'Sich wiederholende Aufgabe bearbeiten' | translate"
      [displayText]=""
      [confirmationText]="'Nur diese Aufgabe' | translate"
      (confirm)="upsertEvent(true)"
      (cancel)="cancelUpdate()">
      <button class="btn btn--gray btn--innershadow btn--full-width"
              aria-label="Confirm"
              (click)="upsertEvent(false)"
              data-cy="modal-all-recurrences"
              translate>Alle Aufgaben
      </button>
    </hc-confirmation-modal>
  `
})
export class EventFormComponent {

  @Output() submitForm = new EventEmitter();
  @ViewChild('description_field', {static: true}) textfield: ElementRef;
  @ViewChild('startSelection', {static: false}) public startSelectionElement: ElementRef;
  @ViewChild('durationSelection', {static: false}) public durationSelectionElement: ElementRef;
  @ViewChild('groupSelection', {static: false}) public groupSelectionElement: ElementRef;
  @ViewChild('privacySelection', {static: false}) public privacySelectionElement: ElementRef;
  @ViewChild('recurrencesSelection', {static: false}) public recurrencsSelectionElement: ElementRef;
  @ViewChild(ConfirmationModalComponent, {static: false}) public modal: ConfirmationModalComponent;

  // todo: refactor this component
  public form: FormGroup;
  public durationSelection$: Observable<Object[]>;
  public lessonTranslations$: Observable<Object[]>;
  public privacyTranslations$: Observable<Object[]>;
  public recurrenceTranslations$: Observable<Object[]>;
  public disableSubmit$: Observable<boolean>;
  public groups$: Observable<Group[]>;
  public labels$: Observable<Label[]>;
  public selectedLabel$: Observable<Label>;
  public hasPremium$: Observable<boolean>;
  public privacySelectionOptions = PRIVACY;
  public labelUserSelection$ = new Subject<Label>();
  public stateSelection = STATES;
  public state = STATES[0]['text'];
  public slotLabels = SLOTS_LABELS;
  public recurrencesSelectionOptions = RECURRENCES;
  public labelSelectorOpen = false;
  public invertedIcon = false;
  public dateSelectorOpen = false;
  public endDateSelectorOpen = false;
  public dateError = false;
  public endDateError = false;
  public dateErrorText = '';
  public event: ViewEvent = ViewEvent.initialObject();
  private backUrl = '';
  private formHasChanged = false;
  private formChangesOverwrite$ = new Subject<boolean>();
  private leavePage$ = new Subject();
  private oldDate: Date = void 0;
  public date: Date = void 0;
  private oldEndDate: Date = void 0;
  public endDate: Date = void 0;
  public minDate: Date = void 0;
  public minEndDate: Date = void 0;
  private alive = true;
  private startSelect: Select = null;
  private durationSelect: Select = null;
  private groupSelect: Select = null;
  private privacySelect: Select = null;
  private recurrencesSelect: Select = null;
  private eventLoaded = false;
  public recurrencesChanges$: Observable<string>
  public confirmationModalShown = false

  constructor(formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private store: Store<reducers.AppState>,
              private cd: ChangeDetectorRef,
              private translateService: TranslateService,
              private analyticsService: AnalyticsService,
              private rruleService: HelloclassRRuleService,
              private actionSheetService: ActionsheetService) {

    this.groups$ = store.select(reducers.getSelectedDisplayGroups);
    this.disableSubmit$ = store.select(reducers.getUpdatingEvent);
    this.store.dispatch(new UiSetTitleAction('Aufgabe erfassen'));

    this.form = formBuilder.group({
      title: [''],
      description: [''],
      group: ['0', Validators.required],
      start: ['0', Validators.required],
      duration: ['1', Validators.required],
      state: ['published', Validators.required],
      isPrivate: ['public', Validators.required],
      uploads: [[]],
      label: ['0', Validators.required],
      recurrences: [0]
    });

    this.labels$ = store.select(reducers.getLabels);

    const label$ = combineLatest([
      this.store.select(reducers.getDetailEvent),
      this.labels$
    ]).pipe(
      map((values) => {
        if (values[0].id !== -1) {
          let foundLabel = values[1].filter(label => label.id === values[0].label);
          let label = foundLabel.length === 1 ? foundLabel[0] : undefined;
          return {label: label, userAction: false};
        } else if (values[1].length > 0) {
          return {label: values[1][0], userAction: false};
        } else {
          return {label: undefined, userAction: false};
        }
      }),
    );

    this.selectedLabel$ = merge(
      label$,
      this.labelUserSelection$.pipe(startWith(EMPTY_LABEL),
        map((label) => {
          let labelData = label.id !== -1 ? label : undefined;
          return {label: labelData, userAction: true};
        }),
      )
    ).pipe(
      filter(data => data.label !== undefined),
      tap((data) => {
        this.form.patchValue({label: data.label.id});
        // reset form changes
        if (!data.userAction) {
          this.formChangesOverwrite$.next(false);
        }
        ;
      }),
      map(data => data.label)
    );

    // select first group. todo: select based on active group
    this.groups$.pipe(
      takeWhile(() => this.alive))
      .subscribe(groups => {
        if (!this.eventLoaded && groups.length > 0) {
          this.form.patchValue({group: groups[0].id});
          // reset form changes
          this.formChangesOverwrite$.next(false);
          this.resetGroupSelection(groups[0].display_name);
        }
      });

    this.durationSelection$ = combineLatest([
      this.translateService.get('Einzellektion').pipe(filter(text => text !== '')),
      this.translateService.get('Doppellektion').pipe(filter(text => text !== '')),
      this.translateService.get('ganzer Tag').pipe(filter(text => text !== ''))
    ]).pipe(
      map((translations) => {
        return [
          {
            text: translations[0],
            duration: '1'
          },
          {
            text: translations[1],
            duration: '2'
          },
          {
            text: translations[2],
            duration: MAX_SLOTS.toString()
          }
        ];
      })
    )

    this.lessonTranslations$ = combineLatest([
      this.translateService.get('Lektion').pipe(filter(text => text !== '')),
      this.translateService.get('Lektionen').pipe(filter(text => text !== '')),
    ])

    this.privacyTranslations$ = combineLatest([
      this.translateService.get('Öffentlich').pipe(filter(text => text !== '')),
      this.translateService.get('Privat').pipe(filter(text => text !== '')),
    ])

    this.recurrenceTranslations$ = combineLatest([
      this.translateService.get('Einmalig').pipe(filter(text => text !== '')),
      this.translateService.get('Täglich').pipe(filter(text => text !== '')),
      this.translateService.get('Wöchentlich').pipe(filter(text => text !== '')),
      this.translateService.get('Monatlich').pipe(filter(text => text !== '')),
    ])

    // change background on color change
    // this.colorValueChangesSubsciption = this.form.controls['cssclass'].valueChanges
    //     .subscribe(cssclass => this.cssclass = CLASS_PREFIX + cssclass);

    this.activatedRoute.queryParams.pipe(
      takeWhile(() => this.alive))
      .subscribe(params => {
        // date of the event
        let selectedDate = params['date'] !== undefined ? dayjs(new Date(parseInt(params['date']))) : dayjs();
        this.updateDates(selectedDate.toDate());

        // position offset
        let startIndex = params['startIndex'] ? params['startIndex'] : '0';

        this.form.patchValue({
          date: selectedDate.format(DATE_FORMAT),
          start: startIndex
        });
      });

    // commented to test: no confirmation on exit
    // this.formSubscription = formChangesStream(this.form, this.formChangesOverwrite$)
    //     .subscribe(formHasChanged => this.formHasChanged = formHasChanged);

    this.translateService.get('Bitte wähle einen Arbeitstag aus').pipe(
      takeWhile(() => this.alive))
      .subscribe(text => this.dateErrorText = text);

    // this.hasPremium$ = store.select(reducers.hasPremium);

    this.recurrencesChanges$ = this.form.get('recurrences').valueChanges.pipe(
      startWith(RECURRENCES[0].id),
    )
  }

  public addUpload(file: FileAttachment) {
    let newEvent = this.event.deepClone();
    newEvent.addUpload(file);
    this.event = newEvent;

    this.form.patchValue({
      uploads: this.event.uploads
    });
  }

  public removeUpload(id: number) {
    let newEvent = this.event.deepClone();
    newEvent.removeUpload(id);
    this.event = newEvent;
    this.form.patchValue({
      uploads: this.event.uploads
    });
  }

  public errorOnUpload() {
    const action = new AddToastAction({message: 'Datei konnte nicht hochgeladen werden.', type: 'fail'});
    this.store.dispatch(action);
  }

  public labelSelected(label: Label) {
    this.labelUserSelection$.next(label);
  }

  public formSubmit(): void {
    if (!this.event.isInitialized() || !this.event.recurrences) {
      this.upsertEvent(false)
    } else {
      if (IS_APP) {
        if (this.event.recurrences) {
          this.showActionSheet()
        } else {
          this.upsertEvent(false)
        }
      } else {
        this.confirmationModalShown = true
        this.modal.show()
      }
    }
  }

  public upsertEvent(singleUpdate: boolean) {
    let event: FormEvent = Object.assign(
      {},
      this.form.value,
      {
        recurrences: this.rruleService.createRRule(+this.form.get('recurrences').value, this.date),
        date: this.date,
        endDate: this.endDate,
        isPrivate: this.isPrivateSelected(this.form.controls['isPrivate'].value),
        recurrencesStart: this.event.recurrences_start
      })

    let action;

    // check & add duration
    switch (event.duration.toString()) { // funny that we need to make a conversion
      case MAX_SLOTS.toString(): // whole day
        event.start = '0';
        break;
      case '2': // Doppellektion
        if (+event.start === MAX_SLOTS - 1) {
          event.start = `${MAX_SLOTS - 2}`;
        }
        break;
      default:
        break;
    }

    // form was submitted, allow to navigate away
    this.formChangesOverwrite$.next(false);

    if (this.event.isInitialized()) {
      event.id = this.event.id;

      if (singleUpdate) {
        event.recurrences = null
      } else {
        if (this.oldDate !== this.date) {
          event.recurrencesStart = dayjs(this.date).format('YYYY-MM-DD')
        }
      }

      action = new UpdateEventAction(event);
    } else {
      action = new AddEventAction(event);
      this.store.select(reducers.getLanguage)
        .pipe(take(1))
        .subscribe(language => {
          this.analyticsService.send(language, 'event', 'Assignments', 'saveAssignment', event.state);
        });
    }
    this.store.dispatch(action);
  }

  public toggleColorSelection() {
    this.labelSelectorOpen = !this.labelSelectorOpen;
  }

  private setStateById(stateId: string) {
    let state = find(STATES, obj => obj.id === stateId);
    this.state = state['text'];
  }

  private selectState(stateId: string) {
    this.setStateById(stateId);
    this.form.patchValue({'state': stateId});
  }

  public onOpenChange() {
    this.invertedIcon = !this.invertedIcon;
  }

  public cancel() {
    // todo: make components unaware of parents
    this.router.navigateByUrl(this.backUrl);
  }

  public startDateChange(selectedDate) {
    const dateChanges = this.dateChange(selectedDate, this.oldDate)
    this.date = dateChanges.date
    this.minEndDate = this.date
    this.dateError = dateChanges.dateError

    if (this.date > this.endDate) {
      this.endDate = this.date
    }
  }

  public endDateChange(selectedDate) {
    const dateChanges = this.dateChange(selectedDate, this.endDate)
    this.endDate = dateChanges.date
    this.endDateError = dateChanges.dateError
  }

  private dateChange(selectedDate, oldDate) {
    let date, dateError
    if (selectedDate.getDay() === 0 || selectedDate.getDay() === 6) {
      date = oldDate;
      dateError = true;
    } else {
      date = selectedDate;
      dateError = false;
    }
    return {date, dateError}
  }

  hasChanges() {
    return this.formHasChanged;
  }

  getUserConfirmation(): Observable<any> {
    return this.leavePage$.asObservable();
  }

  leavePage(leave: boolean) {
    this.leavePage$.next(leave);
  }

  // <--- Ugly start thanks to SDX
  // refactoring did not work
  // maybe a custom component could help https://stackoverflow.com/questions/39661430/angular-2-formcontrolname-inside-component
  private resetStartSelection(placeholder: string) {
    // this.resetSelection(this.startSelect, this.startSelectionElement, placeholder);
    setTimeout(() => {
      this.lessonTranslations$.pipe(
        map((translations: [string, string]) => placeholder === '1' ? translations[0] : translations[1]),
        take(1)
      ).subscribe(translation => {
        if (this.startSelect) {
          this.startSelect.reload();
        } else {
          this.startSelect = new Select(this.startSelectionElement.nativeElement);
        }
        this.startSelect.setPlaceholder(`${placeholder} ${translation}`)
      })
    }, 0);
  }

  private resetDurationSelection(placeholder: string) {
    // this.resetSelection(this.durationSelect, this.durationSelectionElement.nativeElement, placeholder);
    setTimeout(() => {
      if (this.durationSelect) {
        this.durationSelect.reload();
      } else {
        this.durationSelect = new Select(this.durationSelectionElement.nativeElement);
      }
      this.durationSelect.setPlaceholder(placeholder)
    }, 0);
  }

  private resetGroupSelection(placeholder: string) {
    setTimeout(() => {
      if (this.groupSelect) {
        this.groupSelect.reload();
      } else {
        this.groupSelect = new Select(this.groupSelectionElement.nativeElement);
      }
      this.groupSelect.setPlaceholder(placeholder)
    }, 0);
  }

  private resetPrivacySelection(placeholder: string) {
    setTimeout(() => {
      this.privacyTranslations$.pipe(
        map((translations: [string, string]) => placeholder === 'Öffentlich' ? translations[0] : translations[1]),
        take(1)
      ).subscribe(translation => {
        if (this.privacySelect) {
          this.privacySelect.reload();
        } else {
          this.privacySelect = new Select(this.privacySelectionElement.nativeElement);
        }
        this.privacySelect.setPlaceholder(translation)
      })
    }, 0);
  }

  private resetRecurrenceSelection(placeholder: string) {
    setTimeout(() => {
      this.recurrenceTranslations$.pipe(
        map((translations: [string, string]) => {
          const deKeys = RECURRENCES.map(recurrence => recurrence.text)
          const index = deKeys.findIndex(translation => translation === placeholder)
          return index > -1 ? translations[index] : placeholder
        }),
        take(1)
      ).subscribe(translation => {
        if (this.recurrencesSelect) {
          this.recurrencesSelect.reload();
        } else {
          this.recurrencesSelect = new Select(this.recurrencsSelectionElement.nativeElement);
        }
        this.recurrencesSelect.setPlaceholder(translation)
      })
    }, 0);
  }

  // ugly end -->

  ngOnDestroy() {
    this.alive = false;
  }

  private updateDates(date: Date) {
    this.oldDate = date
    this.date = date
    this.oldEndDate = date
    this.endDate = date
  }

  private privacyToTitle(isPrivate: boolean): string {
    return isPrivate ? PRIVACY[1].text : PRIVACY[0].text;
  }

  private privacyToKey(isPrivate: boolean): string {
    return isPrivate ? PRIVACY[1].id : PRIVACY[0].id;
  }

  private isPrivateSelected(key: string): boolean {
    return key === PRIVACY[1].id;
  }

  ngAfterViewInit() {
    this.store.select(reducers.getLanguage)
      .pipe(take(1))
      .subscribe(language => {
        this.analyticsService.send(language, 'event', 'Assignments', 'createAssignment');
      });

    if (!IS_APP && this.textfield) {
      (this.textfield.nativeElement as any)['focus'].apply(this.textfield.nativeElement);
    }

    this.resetStartSelection((+this.form.controls['start'].value + 1).toString());
    this.durationSelection$.pipe(
      take(1))
      .subscribe(durations => this.resetDurationSelection(durations[0]['text']));
    this.resetPrivacySelection(PRIVACY[0].text);

    this.resetRecurrenceSelection(RECURRENCES[0].text)

    this.setupDate()

    let params$ = this.activatedRoute.params.pipe(filter(params => 'id' in params));
    this.setupBacklinks()

    params$.pipe(
      takeWhile(() => this.alive))
      .subscribe((params) => {
        this.store.dispatch(new GetEventAction(params['id']));
      });

    // will only be called if both obs have emited at least one value
    combineLatest([
      params$,
      this.store.select(reducers.getDetailEvent).pipe(filter(event => event.id !== -1))
    ]).pipe(
      takeWhile(() => this.alive)
    ).subscribe((values) => {
      this.event = values[1]
      if (this.event) {
        this.patchExistingEvent()
      }
    });
  }

  private patchExistingEvent() {
    const recurrenceId = this.rruleService.frequencyFromRRule(this.event.recurrences)
    let patchObj = {
      title: this.event.title,
      description: this.event.description,
      group: this.event.group,
      start: this.event.start,
      duration: this.event.duration,
      state: this.event.state,
      isPrivate: this.privacyToKey(this.event.isPrivate),
      uploads: this.event.uploads,
      label: this.event.label,
      endDate: this.event.endDate,
      recurrences: recurrenceId,
    }

    this.eventLoaded = true
    this.store.dispatch(new UiSetTitleAction('Aufgabe editieren'))

    // TODO: add date object to viewevent
    let selectedDate = dayjs(this.event.date)
    this.updateDates(selectedDate.toDate())
    this.endDate = this.event.endDate ? dayjs(this.event.endDate).toDate() : this.date

    this.form.patchValue({date: selectedDate.format(DATE_FORMAT)})

    this.setStateById(this.event.state)
    this.form.patchValue(patchObj)
    this.cd.markForCheck()

    // reset form changes
    this.formChangesOverwrite$.next(false)

    // reset sdx dropdowns
    this.resetStartSelection((this.event.start + 1).toString())
    this.resetPrivacySelection(this.privacyToTitle(this.event.isPrivate))
    this.durationSelection$.pipe(
      take(1))
      .subscribe(durations => this.resetDurationSelection(
        durations.filter(duration => +duration['duration'] === this.event.duration)[0]['text'])
      )
    this.resetGroupSelection(this.event.groupDetails.displayName)
    this.resetRecurrenceSelection(this.rruleService.textForFrequencyId(RECURRENCES, recurrenceId))
  }

  public cancelUpdate() {
    if (this.confirmationModalShown) {
      this.confirmationModalShown = false
      this.modal.hide()
    }
  }

  private setupBacklinks() {
    this.store.select(reducers.getSelectedDate).pipe(
      take(1))
      .subscribe(date => {
        // in case of a new event, navigate back to class book
        if (this.router.url.indexOf('/events/add') > -1) {
          if (date) {
            this.backUrl = `/klassenbuch?date=${date}`;
            this.store.dispatch(new UiSetBackUrl(this.backUrl));
          } else {
            this.backUrl = `/klassenbuch`;
          }
        } else {
          // in case of a editing event, navigate back to event detail
          this.backUrl = `${this.router.url}`.replace('/edit', '');
        }
      });
  }

  private setupDate() {
    this.activatedRoute.queryParams.forEach((params: Params) => {
      // date of the event
      let selectedDate = params['date'] && dayjs(params['date'],
        DATE_FORMAT, true).isValid() ? dayjs(params['date'], DATE_FORMAT) : dayjs();
      this.updateDates(selectedDate.toDate());

      // position offset
      let startIndex = params['startIndex'] ? params['startIndex'] : '0';

      this.form.patchValue({
        date: selectedDate.format(DATE_FORMAT),
        start: startIndex
      });

      // reset form changes
      this.formChangesOverwrite$.next(false);
    });
  }

  private showActionSheet() {
    this.actionSheetService.createActionsheetHandler('Sich wiederholende Aufgabe bearbeiten', 'Abbrechen', '',
      ['saveOnlySingleEventSheet', 'saveAllEventsSheet'],
      () => ({}),
      null,
      [
        () => this.upsertEvent(true),
        () => this.upsertEvent(false)
      ]
    )
  }
}
