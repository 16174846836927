import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, Injectable, NgModule, TransferState} from "@angular/core";
import {BrowserModule, Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {ModalModule} from "ngx-bootstrap/modal";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {ToastrModule} from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';

import {App} from "./app.component";
import {HelloclassRoutingModule} from "./app.routing";
import {metaReducers, reducers} from "./reducers";
import {
  AlertsEffects,
  AppEffects,
  AuthEffects,
  GroupsEffects,
  KlassenbuchEffects,
  LabelEffects,
  LogoutEffect,
  NotificationsEffects,
  PaymentEffects,
  RolesEffect,
  SchoolEffects,
  SignupEffects,
  UiEffects,
  UsersEffects
} from "./effects";
import {FileUploadModule} from 'ng2-file-upload';
import {APP_PROVIDERS} from "./app.providers";
import {APP_COMPONENTS, APP_PIPES} from "./app.declarations";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AlertsModule} from './alerts/alerts.module';
import {HcRequestsModule} from './hc-requests/hc-requests.module';
import {API, BACKEND_BASE_URL, EDULOG_SSO_URL} from './app.tokens';
import {API_URL, BACKEND_URL, EDULOG_SSO, I18N_PATH} from './app.constants';
import {environment} from '../environments/environment';
import {versions} from '../environments/versions';
import {Nl2BrPipeModule} from "./nl2br/nl2br.module";
import {SubscriptionModule} from "./subscription/subscription.module";
import * as Sentry from "@sentry/angular-ivy";
import {LocalLoader} from "./app/local-loader";
import {HelloclassFormModule} from "./forms/form.module";
import {ConfirmationModaleModule} from "./confirmation-modal/confirmation-modal.module";
import {HelloclassOverlayModule} from './overlay/overlay.module';
import {RelationshipModule} from "./relationship/relationship.module";
import {HcDateFormaterModule} from "./dateFormater/dateformater.module";
import {EdulogModule} from "./edulog/edulog.module";
import {LinkyModule} from './linky/linky.module';
import {SignupTermsModule} from "./signup-terms/signup-terms.module";
import {TranslateBrowserLoader} from "./translate-loaders/translate-browser.loader";
import {RouterModule} from "@angular/router";
import {CsrfInterceptor} from "./hc-requests/CsrfInterceptor.service";
import {HelloclassModalModule} from "./modal/modal.module";

// I18n configuration
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient, transferState: TransferState) {

  if (environment.IS_APP) {
    return new LocalLoader(I18N_PATH, '.json');
  }
  return new TranslateBrowserLoader(httpClient, transferState);
}

export function easing(t: number, b: number, c: number, d: number): number {
  // easeInOutExpo easing
  if (t === 0) {
    return b;
  }
  if (t === d) {
    return b + c;
  }
  if ((t /= d / 2) < 1) {
    return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
  }
  return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
}

// SENTRY
// https://github.com/getsentry/sentry-react-native/issues/391#issuecomment-431344211

interface ExtendedError extends Error {
  error: Error; // or `any`
}

Sentry.init({
  dsn: 'https://95ecc5da1c0947c58936206b76c3e86c@sentry.io/177158',
  enabled: environment.ENABLE_SENTRY,
  environment: environment.ENV,
  release: versions.revision,
  beforeSend(event, hint) {
    if (!hint.originalException) {
      return event;
    }

    const customError = (hint.originalException as ExtendedError).error;

    if (event.message && event.message.startsWith && event.message.startsWith('Non-Error exception captured') && customError) {
      Sentry.withScope((scope) => {
        scope.setExtra('nonErrorException', true);
        Sentry.captureException(customError);
      });
      return null;
    }
  }
});

Sentry.configureScope(scope => {
  const tag = environment.IS_APP ? 'app' : 'web';
  scope.setTag('application_type', tag);
})

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    console.warn(error);
    Sentry.captureException(error.originalError || error.error || error);
    // let errorToSend = error;

    // if (error.originalError) {
    //     errorToSend = error.originalError;
    // }

    // if (error.error) {
    //     errorToSend = error.error;
    // }
    // const eventId = Sentry.captureException(errorToSend);
    // Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HcRequestsModule,
    RouterModule,
    LinkyModule,
    NgxPaginationModule,
    NgxPageScrollCoreModule.forRoot({
      duration: 1000,
      easingLogic: easing,
      scrollOffset: 80
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    FileUploadModule,
    SubscriptionModule,
    HelloclassRoutingModule,
    AlertsModule,
    Nl2BrPipeModule,
    HelloclassFormModule,
    ConfirmationModaleModule,
    HelloclassOverlayModule,
    RelationshipModule,
    HcDateFormaterModule,
    EdulogModule,
    SignupTermsModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      AuthEffects,
      GroupsEffects,
      SignupEffects,
      UsersEffects,
      KlassenbuchEffects,
      UiEffects,
      LabelEffects,
      RolesEffect,
      NotificationsEffects,
      AlertsEffects,
      AppEffects,
      PaymentEffects,
      LogoutEffect,
      SchoolEffects
    ]),
    HelloclassModalModule,
  ],
  providers: [
    ...APP_PROVIDERS,
    Title,
    HelloclassRoutingModule,
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: API, useValue: API_URL},
    {provide: BACKEND_BASE_URL, useValue: BACKEND_URL},
    {provide: EDULOG_SSO_URL, useValue: EDULOG_SSO},
    {provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true}
    // {provide: Sentry.TraceService, deps: [Router]},
  ],
  declarations: [
    ...APP_COMPONENTS,
    ...APP_PIPES
  ],
  bootstrap: [App]
})
export class AppModule {
  // constructor(trace: Sentry.TraceService) {}
  constructor() {
  }
}
