import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app';
import {IS_PRODUCTION, USE_MATOMO} from "./app/app.constants";

if (IS_PRODUCTION) {
  enableProdMode();
}

if (USE_MATOMO) {
  let _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    let u = "//hc-analytics-prod.scapp.swisscom.com/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '2']);
    let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      registerServiceWorker('sw-remove');
    });
});

function registerServiceWorker(swName: string) {
  if ('serviceWorker' in navigator) {
    // navigator.serviceWorker
    //     .register(`/${swName}.js`)
    //     .then(reg => {})
    //     .catch(err =>
    //         console.error('[App] Service worker registration failed', err)
    //     );
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  } else {
    console.log('[App] Service Worker API is not supported in current browser');
  }
}
